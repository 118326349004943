<template>
    <div id="app" class="wrapper">
        <div :class="$style.authentication">
            <router-view />
        </div>
    </div>
</template>

<script>
    "use strict";

    import setting from "@/mixins/setting.js";

    export default {
        "mixins": [
            setting,
        ],
    };
</script>

<style lang="scss" module>
    .authentication {
        align-items: center;
        background: rgba(255, 255, 255, 0);
        color: #4c4c4c;
        display: flex;
        justify-content: center;
        min-height: 100vh;
        text-align: center;
        width: 100%;
    }
</style>
